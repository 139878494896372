import Layout from "../components/layout-main";
import { graphql } from "gatsby";

/** @jsx jsx */
import { jsx } from "theme-ui";

const ESignDislocusre = ({ data, location }) => {
  return (
    <Layout location={location} pageTitle="ESign Disclosure" hideSubscribe={true}>
      <section className="aw-accent">
        <div className="aw-content">
          <h1>{data.markdownRemark.frontmatter.title}</h1>
          <p>
            <i>
              Last Updated:{" "}
              {new Date(data.markdownRemark.frontmatter.date)
                .toISOString()
                .slice(0, 10)}
            </i>
          </p>
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        </div>
      </section>
    </Layout>
  );
};

export default ESignDislocusre;

export const pageQuery = graphql`
  query ESignPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/esign.md/" }) {
      html
      frontmatter {
        author
        date
        title
      }
    }
  }
`;
